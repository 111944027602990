<script setup lang="ts">
import { onMounted, ref } from "vue";
import { router } from "@inertiajs/vue3";

const props = defineProps({
    skipAnimation: {
        type: Boolean,
        default: false,
    },
    animationDuration: {
        type: Number,
        default: 500,
    },
});

const show = ref(props.skipAnimation);
router.on("finish", () => {
    setTimeout(() => {
        show.value = true;
    }, props.animationDuration);
});

onMounted(() => {
    setTimeout(() => {
        show.value = true;
    }, props.animationDuration);
});

router.on("before", (event) => {
    show.value = false;

    return true;
});
</script>

<template>
    <transition
        :enter-active-class="'ease-out ' + (props.animationDuration === 300 ? 'duration-300' : 'duration-500')"
        enter-from-class="transform opacity-0 -translate-x-2"
        enter-to-class="opacity-100"
        leave-active-class="ease-in"
        leave-from-class="opacity-100"
        leave-to-class="transform opacity-0 -translate-x-2"
    >
        <div v-show="show" class="h-[calc(100dvh)] p-3 md:p-5">
            <slot />
        </div>
    </transition>
</template>